import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Profile from '../profile/index'
import Product_list from '../product/view'

function index() {

    const navigate = useNavigate();
    const data = null; // Simulating no data. Replace with actual data-fetching logic.
  
    useEffect(() => {
      if (!data) {
        // If no data, redirect to login
        navigate("/login");
      }
    }, [data, navigate]);
  
    if (!data) {
      return null; // Optionally, return null while the redirect is happening
    }

    return (
        <>
            <section className='profile-section'>
                <div className='container'>
                    <Profile />

                </div>
            </section>

            <section className='product-section'>
                <div className='container'>
                    <Product_list/>
                </div>
            </section>
        </>

    )
}

export default index