import React, { useState } from "react";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const secretKey = "your-secret-key"; // Replace with your actual secret key

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the login payload
    const loginData = {
      email,
      password,
      secretKey, // Pass secret key in the request body
    };

    try {
      // Use fetch to make a POST request
      const response = await fetch("https://your-api-endpoint.com/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${secretKey}`, // Alternatively, pass secret key in headers if needed
        },
        body: JSON.stringify(loginData),
      });

      const data = await response.json();

      if (response.ok) {
        console.log("Login successful:", data);
        // Handle successful login here (e.g., redirect, save token)
      } else {
        console.error("Login failed:", data.message);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center bg-light p-5">
        <div className="col-md-4">
          <h2 className="text-center">Login</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="m-3 text-center">
            <button type="submit" className="btn btn-outline-success">
              Login
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
