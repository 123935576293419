import React from 'react'

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from './Login'

import Signup from './Signup'
import Customer from './Customer'
import Store from './store/index'
import Product from './product/index'


function index() {
  return (
    <>
      <BrowserRouter>
        <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/login" element={<Login />} />
          {/* <Route path="/sign" element={<Signup />} /> */}
          <Route path="/" element={<Store />} />
          {/* <Route path="/store" element={<Store />} />
          <Route path="/product" element={<Product />} /> */}


        </Routes>
      </BrowserRouter>
    </>
    // <div>
    //   <p>hello</p>

    //   <Prop mobile="989900" />

    // </div>

  )
}



const NotFoundPage = () => {
  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};

export default index